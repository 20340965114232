import { translate } from '@/messages'

export default [
  {
    text: translate('base.id'),
    align: 'start',
    value: 'id'
  },
  {
    text: translate('organization.name'),
    align: 'start',
    value: 'organization',
    sortable: false
  },
  {
    text: translate('subscription.fromDate'),
    align: 'start',
    value: 'from_date'
  },
  {
    text: translate('subscription.toDate'),
    align: 'start',
    value: 'to_date'
  },
  {
    text: translate('subscription.price'),
    align: 'start',
    value: 'price'
  },
  {
    text: translate('subscription.passportList'),
    align: 'start',
    value: 'passports',
    sortable: false
  },
  {
    text: translate('subscription.status'),
    align: 'start',
    value: 'isEvaluated',
    sortable: false
  }
]
