<template>
  <tr>
    <td @click="detail(item.id)">{{ item.id }}</td>
    <td @click="detail(item.id)">{{ item.organization.name }}</td>
    <td @click="detail(item.id)">{{ timestampToDate(item.from_date) }}</td>
    <td @click="detail(item.id)">{{ timestampToDate(item.to_date) }}</td>
    <td @click="detail(item.id)">{{ item.price }}</td>
    <td ref="bigContent">
      <div v-if="!item.subscriptionPassports.length">
        —
      </div>

      <div
        v-for="element in item.subscriptionPassports"
        :key="element.id"
        v-if="height <= 55 || item.subscriptionPassports.length === 1 "
        @click="detail(item.id)"
      >
        {{ element.passport.properties.object_name }}
      </div>

      <BaseExpansionPanel
        v-if="item.subscriptionPassports.length > 1"
        :height="height"
      >
        <template #header>
          <div
            v-for="(element, index) in item.subscriptionPassports"
            :key="element.id"
            v-if="index === 0 "
          >
            {{ element.passport.properties.object_name }} <span
            class="grey--text"
            v-if="!isOpen"
          >(+ {{ item.subscriptionPassports.length - 1 }} {{ passportWord }})</span>
          </div>
        </template>
        <template #content>
          <div
            v-for="(element, index) in item.subscriptionPassports"
            :key="element.id"
            v-if="index !== 0"
          >
            {{ element.passport.properties.object_name }}
          </div>
        </template>
      </BaseExpansionPanel>
    </td>
    <td :class="statusSubscription.color + '--text'">
      {{ statusSubscription.text }}
    </td>
  </tr>
</template>

<script>
import formatters from '@/components/mixins/formatters'
import listItem from '@/components/mixins/listItem'
import BaseExpansionPanel from '@/components/base/BaseExpansionPanel'

export default {
  mixins: [formatters, listItem],
  components: { BaseExpansionPanel },
  name: 'ListItem',
  data () {
    return {
      height: null,
      isOpen: false
    }
  },
  mounted () {
    this.height = this.$refs.bigContent.clientHeight
  },
  computed: {
    passportWord () {
      let result = 'паспорт'
      const countPassports = String(this.item.subscriptionPassports.length - 1).split('')
      const lastElement = Number(countPassports[countPassports.length - 1])
      if (lastElement >= 2 && lastElement <= 4) {
        result += 'а'
      } else if (lastElement === 0 || lastElement >= 5 && lastElement <= 9) {
        result += 'ов'
      }
      return result

    },
    statusSubscription () {
      const listStatus = {
        active: {
          color: 'green',
          text: 'Активна'
        },
        evaluated: {
          color: 'orange',
          text: 'Истекает'
        },
        finished: {
          color: 'red',
          text: 'Окончена'
        }
      }
      return listStatus[this.item.isEvaluated]
    }
  }
}
</script>

<style scoped>
  tr {
    height: 55px;
  }
</style>
