<template>
  <div
    v-if="height > maxHeight"
    style="z-index: 1000"
  >
    <v-expansion-panels class="elevation-0">
      <v-expansion-panel class="elevation-0">
        <v-expansion-panel-header
          class="elevation-0 ml-0 pl-0 pa-0"
          @click="isOpen = !isOpen"
        >
          <slot name="header"/>
        </v-expansion-panel-header>

        <v-expansion-panel-content
          class="ml-0 pl-0 elevation-0"
          :class="isOpen ? 'd-flex' : 'd-none'"
        >
          <slot name="content"/>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
export default {
  name: 'BaseExpansionPanel',
  props: {
    height: Number,
    maxHeight: {
      type: Number,
      default: 55
    }
  },
  data () {
    return {
      isOpen: false
    }
  }
}
</script>

<style scoped>
  .theme--dark.v-expansion-panels .v-expansion-panel,
  .theme--light.v-expansion-panels .v-expansion-panel {
    background-color: transparent;
  }
  .v-expansion-panel::before {
    box-shadow: none;
  }
  /deep/ .v-expansion-panel-content__wrap {
    padding: 0;
    margin: 0;
  }
  .v-expansion-panel--active,.v-expansion-panel-header {
    min-height: 0;
  }
</style>