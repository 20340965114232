<template>
  <BaseList
    :headers="headers"
    :controller="'subscription'"
    :query="{filter}"
    :can-create="$can(null, 'editSubscription')"
  >
    <template #item="{item}">
      <ListItem
        :key="item.id"
        :item="item"
      />
    </template>
  </BaseList>
</template>

<script>
import ListItem from './list/ListItem'
import BaseList from '@/components/base/BaseList'
import headers from './list/headers'

export default {
  name: 'List',
  components: { ListItem, BaseList },
  data () {
    return {
      headers: headers
    }
  },
  computed: {
    filter () {
      let filter = {}
      if (this.$route.name === 'organizationSubscriptions') {
        filter = {
          organization_id: this.$route.params.id
        }
      }
      return filter
    }
  }
}
</script>

<style scoped>

</style>
